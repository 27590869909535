.input {
  @apply bg-white text-lg placeholder:text-lg;
}
.extraSmall {
  @apply h-8;
}
.small {
  @apply h-11;
}
.middle {
  @apply h-14;
}
.large {
  @apply h-16;
}
