.radio {
  @apply m-0 border-none text-primaryLight;
  @apply border-s-0 #{!important};

  &:hover {
    @apply text-primary;
  }

  :global {
    .ant-radio:not(.ant-radio-disabled) {
      & .ant-radio-inner::after {
        @apply bg-accent #{!important};
      }
    }

    .ant-radio-disabled {
      .ant-radio-inner {
        @apply border-accentLight #{!important};
      }
    }

    .ant-radio-inner {
      @apply bg-white border-accent scale-125 #{!important};
      &::after {
        @apply scale-65 #{!important};
      }
    }

    .ant-radio-button-checked {
      @apply bg-accent rounded-lg;
    }

    .ant-radio-button-wrapper-checked {
      @apply bg-accent;
    }
  }
}
