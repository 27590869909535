.modal {
  :global {
    .ant-modal-content {
      @apply px-4 pt-6 pb-4 bg-white desktop:p-7 #{!important};
    }
  }
}

.transparent {
  :global {
    .ant-modal-content {
      @apply bg-transparent shadow-none #{!important};
    }
  }
}

.paddingLess {
  :global {
    .ant-modal-content {
      @apply p-0 #{!important};
    }
  }
}

.marginLess {
  :global {
    .ant-modal-footer {
      @apply mt-0 #{!important};
    }
  }
}
